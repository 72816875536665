@charset "utf-8";



// Our variables
$base-font-family: 'Open Sans', Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #545454;
$background-color: #fdfdfd;
$brand-color:      #4C36EC;

$grey-color:       #777777;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$on-palm:          1024px;
$on-laptop:        1024px;



// Using media queries with like this:
// @include media-query($palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}
@media only screen {


    figure {
        margin:0;
    }

    .thumbimg{
        display: block;
        overflow: hidden;
        max-width: 100%;

    }


    .site-title {
        float:left;
        font-size: 26px;
        line-height: 56px;
        letter-spacing: -1px;
        margin-bottom: 0;
        height: 32px;
        text-align: left;
        text-decoration: none;
        z-index:0;
        color: black;
        width: 300px;
    }
    .page-link{
        color: $brand-color;
    }
    .site-nav .page-link{
        text-decoration: none;
        color: $brand-color;
        &:hover {
            color: darken($brand-color, 50%);
            border-bottom: 2px solid $brand-color;
        }
    }

    .site-nav .page-link.selected {
        color: $text-color;
        border-bottom: 2px solid $brand-color;
    }

    .site-nav .page-link.selected:hover {
        text-decoration: none;
    }
}


@media only screen and (min-width: 768px) {


}




// Import partials from `sass_dir` (defaults to `_sass`)
@import
"base",
"layout",
"syntax-highlighting"
;





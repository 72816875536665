
/**
 * Site header
 */
.site-header {
    min-height: 56px;
    margin-top: 56px;
    text-align:right;
    // Positioning context for the mobile navigation icon
    position: relative;
}


h1.workTitle {
    font-size: 1.4em;
}


 .site-title h3{
        font-size: 1em;
}

.site-nav {
    line-height: 56px;
    margin: 0 auto;
    float: right;
    .page-link {
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {


        h1{
            font-size: 1.2em;
        }
     

        .menu-icon {


            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {

        }

        &:hover .trigger {


        }

        .page-link {

        }
    }
}



/**
 * Site footer
 */
.site-footer {
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}


/**
 * Case Studies layout elements
 */

     .cs-overview-col{
        margin-bottom: 30px;
    }



     .cs-header{
        height: 500px;
        width: 100%;
        background-color: #00ACAF;
        display: flex;
        align-items:center;
        justify-content:center;
        
     }

     .cs-grey-center{
        background-image: linear-gradient(180deg, #FAFAFA 0%, #F0F0F0 100%);
        padding-top: 3em;
        padding-bottom: 3em;
        text-align: justify;
        .paragraph{
            padding-top:30px;
            padding-bottom: 30px;
            text-align: center;
            .description{            
                text-align: justify;
                margin-bottom: 30px;
            }
            img {
                margin-right: auto;
                margin-left: auto;
            }
        }
     }

     .cs-caption{
        font-weight: 700;
        text-align: center;
        margin-top: 30px;


     }

        a{
            .cs-images{

            }
        }
    .cs-images{
        text-align: center;
        margin-bottom: 30px;
        max-width: 80%;
 
    }

      .cs-white-center{
        background-color: white;
        padding-top: 3em;
        padding-bottom: 3em;
        text-align: center;
        h3{
            text-align: center;
        }
        .paragraph{
            padding-top:30px;
            padding-bottom: 30px;
            text-align: center;
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            flex: 1;
            .description{            
                text-align: justify;
                margin-bottom: 30px;
                margin-right: auto;
                margin-right: auto;
            }
            img {
                margin-right: auto;
                margin-left: auto;
            }
        }
     }


        .on-case-study{
                position: absolute;
                z-index: 10;
                min-height: 56px;
                margin-top: 56px;

                text-align: right;
                width: 100%;
            

        }


        .projectThumb{
            width: 100%;
            height: 320px;
            background-color: #101010;
            border-radius: 8px;
            margin-bottom: 16px;
            text-align: center;
            float: left;
            overflow: hidden;
            background-position: center;
            background-size: cover;
             a{
                text-decoration: none;
                color: black;
                background-color: #f0f0f0;
                border-radius: 8px;
                height: 100%;
                width: 100%;
            }
        }

     .projectThumb .thumbLabel{
                    display: flex;
                    align-items:center;
                    justify-content:center;
                    background-color: #f0f0f0;
                    opacity: 0;
                    height: 100%;
                    transform: scale(1);
                    transition: all 300ms;

                }

        .projectThumb:hover .thumbLabel{
                    display: flex;
                    align-items:center;
                    justify-content:center;
                    background-color: #f0f0f0;
                    opacity: 0.9;
                    height: 100%;
                    transform: scale(1.05);
                    transition: all 600ms;

                }

        


     @media only screen and (min-width: 768px) {


    h1.workTitle{
        font-size: 2em;
    }


    .site-title h3{
        font-size: 1.17em;
    }

    .site-nav {
        width: calc(100% - 300px);
    }

    .site-nav.on-case-study {
        width: 100%;
    }

    .flex-container {
      display: flex;
    }

     .cs-overview-col{
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        margin-right: 30px;
            &:last-of-type {
                margin-right: 0;
            }

     }


      .cs-white-center, .cs-grey-center{
            .paragraph p.description{
                max-width: 40%;
                margin: auto;
            }
        }

        .projectThumb{
            width: 49%;
            float: left;
            margin-right:2%;

            &:hover {
                transition: all .3s ease;
                -moz-transition: all .3s ease;
                -webkit-transition: all .3s ease;
                -o-transition: all .3s ease;

            }

        }


        .projectThumb:nth-child(2n) {
        margin-right:0%;
        }

        .thumbLabel{
            display: none;

            &:hover {
                display: block;

            }
        }
         
    }

        